import _ from "underscore"
import axios from "axios"

const fotos = new Vue({
  el: "#tafalla-fotos",
  data: function() {
    return {
      opened: false,
      image: null,
    }
  },
  created: function() {
  },
  methods: {
    change: function(src) {
      this.image = src
      this.opened = true
    }
  }
})

const app = new Vue({
  el: "#tafalla-form",
  data: function() {
    return {
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        ccaa: "",
        amount: 10,
        legal1: false,
        legal2: false,
      },
      error: {
        name: false,
        surname: false,
        email: false,
        phone: false,
        ccaa: false,
        amount: false,
      },
      showError: false,
      paying: true,
    }
  },
  computed: {
    valid: function() {
      let isValid = true
      let errorFields = []

      if (_.isEmpty(this.form.name)) {
        isValid = false
        errorFields.push("name")
      }

      if (_.isEmpty(this.form.surname)) {
        isValid = false
        errorFields.push("surname")
      }

      if (_.isEmpty(this.form.email)) {
        isValid = false
        errorFields.push("email")
      }

      if (_.isEmpty(this.form.phone)) {
        isValid = false
        errorFields.push("phone")
      }

      if (_.isEmpty(this.form.ccaa)) {
        isValid = false
        errorFields.push("ccaa")
      }

      if (!this.form.legal1) isValid = false
      if (!this.form.legal2) isValid = false

      let amountCasted = parseInt(this.form.amount, 10)
      if (amountCasted < 10) {
        isValid = false
        errorFields.push("amount")
      }

      this.cleanErrors()
      _.each(errorFields, errorField => {
        this.error[errorField] = true
      })

      return isValid
    }
  },
  created: function() {
    $("#qtmPlayerMobenable").hide()
  },
  mounted: function() {
    $("#qtmPlayerMobenable").hide()
    setTimeout(function() {
      $("#qtmPlayerMobenable").hide()
    }, 500)
    $(".tafalla-submit").addClass("loaded")
  },
  methods: {
    cleanErrors: function() {
      this.error.name = false
      this.error.surname = false
      this.error.email = false
      this.error.phone = false
      this.error.ccaa = false
      this.error.amount = false
    },
    pay: function() {
      const _this = this

      if (this.valid) {
        axios.post("/tafalla-payment/form.php", {
          name: this.form.name,
          surname: this.form.surname,
          email: this.form.email,
          phone: this.form.phone,
          ccaa: this.form.ccaa,
          amount: this.form.amount,
        })
          .then(response => {
            if (response.data.env == "STRIPE") {
              _this.paying = true
              let session = response.data.session
              let stripe = Stripe('pk_live_Ur8jsV7mwesg2jU0A1qUbGmQ00zYIZD6EM') // PROD
              //let stripe = Stripe('pk_test_2WPa0LfqkThxyJmGmae0VDnL00yaOnjDmc') // TEST

              stripe.redirectToCheckout({
                sessionId: session
              }).then(function (result) {
                // on error
              })
            } else {
              _this.paying = true
              let data = response.data
              let tafallaId = data.tafalla
              let json = JSON.parse(data.payment)

              RealexHpp.setHppUrl("https://hpp.sandbox.realexpayments.com/pay")
              RealexHpp.redirect.init("paymentButton", "https://"+window.location.hostname+"/tafalla-payment/callback.php?id="+tafallaId, json)
              console.log("here!")

              setTimeout(function() {
                $("#paymentButton").trigger("click")
              }, 200)
            }
          })
          .catch(err => {
            console.log(err)
          })

      } else {
        this.showErrors = true
      }
    }
  }
})
